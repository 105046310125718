.partThreeDivide{


    display: grid;
    grid-template-columns: 1fr 6fr;
}

.stackpos{

    position: sticky;
    top: 30px;
    
}

/* .glass{

    

background: rgba(16, 222, 234, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(16, 222, 234, 0.3);
} */