
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

 #customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  
    text-align: center;
}

/*
#customers td:first-child {
    width: 10px;
    text-align: center;
  } */




#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  /* padding-top: 12px;
  padding-bottom: 12px;
  text-align: left; */
  background-color: #04AA6D;
  color: white;
}
