.adminpart{


    display: grid;
    grid-template-columns: 1fr 3fr;
}
.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .checkbox-label {
    margin-right: 10px;
  }
  
  .checkbox-label input[type="checkbox"] {
    margin-right: 5px;
  }