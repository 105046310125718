.colorBlue{

    background-color: #19496F;
}

.colorDarkBlue{

    background-color: #0E2B44;
}
.colorLightBlue{

    background-color: #265479;
}

.colorRating{

    background-color: #0E2B44;
}