th:first-child,
td:first-child {
  width: 10px;
}
td:nth-of-type(2) {
    white-space: pre-wrap;
  }

  /*  */
  
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

 #customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  
    text-align: center;
}

/*
#customers td:first-child {
    width: 10px;
    text-align: center;
  } */


  /* #customers td:first-child {
    width: 10%;
    text-align: center;
  }
  #customers td:not(:first-child) {
    width: 45%;
  } */

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  /* padding-top: 12px;
  padding-bottom: 12px;
  text-align: left; */
  background-color: #04AA6D;
  color: white;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height to match your layout */
}

.spinner {
  width: 100px;
  height:100px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


 